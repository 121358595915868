import React, { useEffect } from "react";
import SEO from "../components/seo";
import { useStore } from "../lib/store";

const About: React.FC = () => {
  const { setMenuLocation } = useStore();
  useEffect(() => {
    setMenuLocation("about");
  }, []);

  return <SEO title={"About"} description={"About me"} />;
};

export default About;
